import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import classes from './App.module.scss'
import heart from './images/Heart-stone.svg'

class Line {
	public x0: number

	public y0: number

	public x1: number

	public y1: number

	public constructor(x0: number, y0: number, x1: number, y1: number) {
		this.x0 = x0
		this.y0 = y0
		this.x1 = x1
		this.y1 = y1
	}
}

interface Rect {
	x: number
	y: number
	width: number
	height: number
}

function boxToRect(domRect: DOMRect, offset: DOMRect): Rect {
	return {
		x: domRect.x + offset.x,
		y: domRect.y + offset.y,
		width: domRect.width,
		height: domRect.height
	}
}

enum RectSide {
	Left,
	Right,
	Top,
	Bottom
}

function getLineFromRect(side: RectSide, rect: Rect): Line {
	switch (side) {
		case RectSide.Left:
			return new Line(rect.x, rect.y, rect.x, rect.y + rect.height)
		case RectSide.Right:
			return new Line(rect.x + rect.width, rect.y, rect.x + rect.width, rect.y + rect.height)
		case RectSide.Top:
			return new Line(rect.x, rect.y, rect.x + rect.width, rect.y)
		case RectSide.Bottom:
			return new Line(rect.x, rect.y + rect.height, rect.x + rect.width, rect.y + rect.height)
	}
}

function areLinesIntersecting(lineA: Line, lineB: Line): boolean {
	const denomY0 = (lineA.y0 - lineB.y0)
	const denomX0 = (lineA.x0 - lineB.x0)
	const denom = 1 / ((lineB.y1 - lineB.y0) * (lineA.x1 - lineA.x0) - (lineB.x1 - lineB.x0) * (lineA.y1 - lineA.y0))
	const slopeA = ((lineB.x1 - lineB.x0) * denomY0 - (lineB.y1 - lineB.y0) * denomX0) * denom
	const slopeB = ((lineA.x1 - lineA.x0) * denomY0 - (lineA.y1 - lineA.y0) * denomX0) * denom

	return slopeA >= 0 && slopeA <= 1 && slopeB >= 0 && slopeB <= 1
}

function doesLineIntersectRect(line: Line, rect: Rect): boolean {
	return areLinesIntersecting(line, getLineFromRect(RectSide.Left, rect)) ||
		areLinesIntersecting(line, getLineFromRect(RectSide.Right, rect)) ||
		areLinesIntersecting(line, getLineFromRect(RectSide.Top, rect)) ||
		areLinesIntersecting(line, getLineFromRect(RectSide.Bottom, rect))
}

export default function App() {
	const embedElemRef = useRef<HTMLEmbedElement>(null)
	const boxElemRef = useRef<HTMLDivElement>(null)
	const animationActiveRef = useRef(false)

	const [isLoaded, setIsLoaded] = useState(false)

	const brightnessAnimation = useCallback(() => {
		const elem = embedElemRef.current
		const boxElem = boxElemRef.current
		const svgDocument = elem?.getSVGDocument()

		if (!animationActiveRef.current || !boxElem || !elem || !svgDocument)
			return

		const offset = elem.getBoundingClientRect()
		const box = boxElem.getBoundingClientRect()
		const line = new Line(box.x, box.y + box.height, box.x + box.width, box.y)

		const paths = svgDocument.querySelectorAll<SVGPathElement>('path:not(.ignore)')
		for (let path of Array.from(paths)) {
			const rect = boxToRect(path.getBBox(), offset)
			if (doesLineIntersectRect(line, rect)) {
				path.setAttribute('filter', 'url(#brightness)')
			} else {
				path.removeAttribute('filter')
			}
		}

		requestAnimationFrame(brightnessAnimation)
	}, [])

	const stopAnimation = () => {
		animationActiveRef.current = false
	}

	useEffect(() => {
		if (!isLoaded)
			return

		animationActiveRef.current = true
		requestAnimationFrame(brightnessAnimation)
	}, [isLoaded, brightnessAnimation])

	return (
		<div className={classes.root}>
			<div className={classes.embedContainer}>
				<div
					className={clsx(classes.box, {
						[classes.slide]: isLoaded
					})}
					ref={boxElemRef}
					onAnimationEnd={stopAnimation}
				/>
				<embed src={heart} ref={embedElemRef} onLoad={() => setIsLoaded(true)}/>
			</div>
			<div className={classes.title}>Sapphire Behavioral Healthcare</div>
			<div>Coming soon.</div>
			<div className={classes.email}>
				For all inquiries, email us here:
				<a href="mailto:info@sapphirebehavioralhealthcare.com">info@sapphirebehavioralhealthcare.com</a>
			</div>
		</div>
	)
}
